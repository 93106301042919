.pageserror {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  padding: 0 15px;
  &#layout-top{
    margin-top: 124px;
  }
  &_msn {
    &_img {
      width: 72px;
      height: 72px;
      margin-bottom: 20px;
    }
    &_title {
      //   font-family: $font-roboto-condensed;
      color: $mainColor;
      font-size: 28px;
      font-weight: 600;
      line-height: 28px;
      text-align: center;
      margin-bottom: 10px;
    }
    .content-pages-error {
      min-height: calc(100vh - 126px);
      @media (min-width: 600px) {
      }
      &_content {
        //   font-family: $font-roboto-condensed;
        //   color: $gray-05;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        margin-bottom: 5px;
      }
      .btn {
        margin: 30px auto 0;
        display: block;
        max-width: 260px;
      }
    }
  }
}
.content-pages-error {
  min-height: calc(100vh - 30px);
  @media (max-width: 767px) {
    min-height: inherit;
    padding: 150px 15px 50px;
  }
}

@media screen and (max-width:767px){
  .pageserror{
    &#layout-top{
      margin-top: 40px;
    }
  }
}