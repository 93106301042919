@import "../global/variables";
.form_item{
    margin-bottom: 15px;
    .error {
        color: red;
        font-family: $mainFont;
        font-size: 11px;
        line-height: 14px;
        margin-bottom: 0;
    }
    .error-gray {
        color: #666666;
        font-family: $mainFont;
        font-size: 11px;
        line-height: 14px;
        margin-bottom: 0;
    }
    &.no_margin {
        margin-bottom: 10px;
    }
    &.input_file {
        &.file_error {
            .file_content {
                border: 1px solid red;
                margin-bottom: 5px;
                .text {
                    color: red;
                }
            }
        }
        .file_content {
            background: url("./../../../assets/img/iconos/ic_plus.svg") no-repeat center $lightColor;
            background-position-x: calc(100% - 14px);
            background-size: 14px;
            border: 1px solid $border_inputs;
            border-radius: 5px;
            height: 55px;
            line-height: 55px;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0 20px 0 12px;
            position: relative;
            white-space: nowrap;
            &.file_show {
                background: url("./../../../assets/img/iconos/ic_gray_close.svg") no-repeat center $lightColor;
                background-position-x: calc(100% - 11px);
                background-size: 20px;
            }
            input {
                left: 0;
                height: 100%;
                opacity: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
            .text {
                color: $u1-04;
                font-size: 16px;
            }
        }
    }
    &.checkbox-secondary{
        mat-checkbox {
            &.mat-checkbox-checked {
                &.mat-accent,
                &.mat-checkbox-indeterminate {
                    .mat-checkbox-background {
                        background: $secondColor;
                    }
                }
            }
            flex-wrap: wrap;
            .mat-checkbox-layout {
                align-items: flex-start;
                vertical-align: top;
                white-space: normal;
            }
            .mat-checkbox-frame {
                border: 1px solid #d7d7d7;
                border-radius: 4px;
            }
            .mat-checkbox-label {
                font-family: $mainFont;
                color: #1B1B1B;
                font-size: 16px;
                line-height: 19px;
                a {
                    font-family: $mainFont;
                    color: #007EFF;
                    font-size: 16px;
                    line-height: 19px;
                    text-decoration: underline;
                }
            }
            .mat-checkbox-inner-container {
                margin: 2px 10px 0 0px;
                vertical-align: top;
            }
        }
    }
    &.checkbox_item {
        mat-checkbox {
            &.mat-checkbox-checked {
                &.mat-accent,
                &.mat-checkbox-indeterminate {
                    .mat-checkbox-background {
                        background: $mainColor;
                    }
                }
            }
            flex-wrap: wrap;
            .mat-checkbox-layout {
                align-items: flex-start;
                vertical-align: top;
                white-space: normal;
            }
            .mat-checkbox-frame {
                border: 1px solid #d7d7d7;
                border-radius: 4px;
            }
            .mat-checkbox-label {
                font-family: $mainFont;
                color: #333333;
                font-size: 12px;
                line-height: 16px;
                a {
                    font-family: $mainFont;
                    color: #333333;
                    font-size: 12px;
                    line-height: 16px;
                }
            }
            .mat-checkbox-inner-container {
                margin: 2px 15px 0 0px;
                vertical-align: top;
            }
        }
    }
    mat-select {
        .mat-select-arrow-wrapper {
            transform: translateY(0);
            padding: 0;
            .mat-select-arrow {
                border: 0;
                height: 15px;
                line-height: 15px;
                width: 15px;
                &:before {
                    content: "\e900";
                    color: $mainColor;
                    font-family: $iconFont;
                }
            }
        }
    }
    &.more_less {
        label {
            color: $mainColor;
            display: block;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 10px;
            width: 100%;
        }
        &.field_error {
            .material-spinners {
                input {
                    border-color: red;
                    box-shadow: none;
                    &:hover,
                    &:focus {
                        border-color: red;
                    }
                }
            }
        }
        .error {
            margin-top: 5px;
        }
        .material-spinners {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            a {
                border: 1px solid $border_inputs;
                border-radius: 5px;
                color: $tag;
                cursor: pointer;
                display: inline-block;
                font-size: 18px;
                height: 55px;
                line-height: 55px;
                text-align: center;
                vertical-align: middle;
                width: 55px;
            }
            input {
                border: 1px solid $border_inputs;
                border-radius: 5px;
                display: inline-block;
                height: 55px;
                padding: 0 12px;
                transition: all 0.2s;
                text-align: center;
                width: calc(100% - 120px);
                &:focus,
                &:hover {
                    border: 2px solid $fiveColor;
                    box-shadow: none;
                    outline: none;
                    transition: all 0.2s;
                }
            }
        }
    }
    &.slide_toggle {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        label {
            color: $mainColor;
            display: block;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 0px;
            max-width: 80%;
        }
        .error {
            width: 100%;
        }
        .toggle_field {
            position: relative;
            input {
                cursor: pointer;
                height: 100%;
                left: 0;
                opacity: 0;
                position: absolute;
                width: 100%;
                z-index: 1;
                &:checked {
                    & + .labels {
                        background: $borderCard;
                        color: $lightColor;
                        &:before {
                            left: calc(100% - 28px);
                        }
                        span {
                            color: $lightColor;
                        }
                    }
                }
            }
            .labels {
                align-items: center;
                background-color: #ccc;
                border-radius: 22px;
                cursor: pointer;
                display: flex;
                height: 30px;
                line-height: 30px;
                justify-content: space-between;
                min-width: 60px;
                overflow: hidden;
                padding: 0 8px;
                transition: all 0.3s cubic-bezier(0.79, -0.01, 0.36, 1);
                position: relative;
                &:before {
                    background-color: #fff;
                    box-shadow: 0 1px 1px #7d7d7d;
                    border-radius: 50%;
                    content: "";
                    display: block;
                    height: 24px;
                    left: 3px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: left 0.3s cubic-bezier(0.79, -0.01, 0.36, 1);
                    width: 24px;
                }
                span {
                    color: $iu-01;
                    font-size: 14px;
                    font-weight: 700;
                }
            }
        }
    }

    .hour_picker {
        input {
            background: url("../../../assets/img/iconos/icono_reloj.svg") no-repeat;
            background-position: calc(100% - 10px) center;
            background-size: 22px;
            border: 1px solid $border_inputs;
            border-radius: 5px;
            display: block;
            height: 55px;
            padding: 0 12px;
            transition: all 0.2s;
            width: 100%;
            &:focus,
            &:hover {
                border: 2px solid $fiveColor;
                box-shadow: none;
                outline: none;
                transition: all 0.2s;
            }
        }
        mat-hint {
            color: red;
            font-size: 11px;
            top: -4px;
            position: relative;
        }
        &.field_error {
            input {
                border-color: red;
                margin-bottom: 5px;
            }
        }
    }
    &.textarea {
        mat-form-field {
            height: auto !important;
            &.mat-form-field-appearance-outline {
                .mat-form-field-infix {
                    height: auto !important;
                }
                .mat-form-field-outline {
                    height: auto;
                }
                textarea {
                    height: 32px !important;
                    max-height: 80px;
                    padding-top: 9px;
                    &.mat-input-element {
                        min-height: fit-content;
                    }
                }
            }
        }
    }
    &.radio_button {
        &.field_error {
            label {
                input {
                    border-color: red;
                }
            }
        }
        label {
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            margin: 0;
            padding: 22px 15px;
            position: relative;
            text-align: center;
            input {
                border: 1px solid $border_inputs;
                border-radius: 5px;
                cursor: pointer;
                height: 100%;
                left: 0;
                margin: 0;
                position: absolute;
                top: 0;
                width: 100%;
                @include prefix(appearance, none, webkit moz o ms);
                &:checked {
                    border: 2px solid $secondColor;
                    & + strong {
                        color: $secondColor;
                    }
                }
                &:hover,
                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
            strong {
                color: $ui-05;
                display: block;
                font-size: 26px;
                font-weight: bold;
                line-height: 32px;
            }
            span {
                color: $iu-01;
                display: block;
                font-size: 14px;
            }
        }
    }
    .radio_group {
        display: flex;
        flex-wrap: wrap;
        .radio-img {
            margin-bottom: 10px;
            margin-right: 25px;
            width: calc(50% - 12.5px);
            &:nth-child(2n) {
                margin-right: 0;
            }
        }
    }
}
.form_item,
.cancel-service,
form-available{
    mat-form-field {
        display: block;
        width: 100%;
        &.edit-field {
            .mat-form-field-flex{
                background: url("./../../../assets/img/iconos/icon_edit.svg") no-repeat center 18px;
                background-position-x: calc(100% - 10px);
                background-size: 26px;
            }
            input{
                box-sizing: border-box;
                max-width: 100%;
                text-overflow: ellipsis;
                padding-right: 40px;
            }
        }
        &.mat-focused {
            .mat-form-field-label {
                color: $fiveColor;
                font-weight: bold;
            }
        }
        &.mat-form-field-disabled {
            &.mat-form-field-appearance-outline {
                .mat-form-field-outline {
                    background: $disabled;
                    border-radius: 5px;
                }
            }
            &.txt-black {
                .mat-form-field-outline {
                    background: transparent;
                }
                .mat-form-field-label {
                    color: $u1-04;
                }
            }
        }
        &.mat-form-field-invalid {
            .mat-form-field-label {
                color: $u1-04;
            }
        }
        mat-select {
            &.mat-select-invalid {
                .mat-select-value {
                    color: $iu-01;
                }
                .mat-select-arrow-wrapper {
                    .mat-select-arrow {
                        &:before {
                            color: $mainColor;
                        }
                    }
                }
            }
        }
        &.field_error {
            .mat-form-field-label {
                color: red;
            }
            mat-select {
                &.mat-select-invalid {
                    .mat-select-value {
                        color: red;
                    }
                    .mat-select-arrow-wrapper {
                        .mat-select-arrow {
                            &:before {
                                color: red;
                            }
                        }
                    }
                }
            }
            &.mat-form-field-appearance-outline {
                &.mat-form-field-invalid {
                    &.mat-focused {
                        .mat-form-field-outline-thick {
                            color: red;
                        }
                    }
                }
                &.mat-form-field-can-float {
                    &.mat-form-field-should-float {
                        .mat-form-field-label {
                            color: red;
                            mat-label {
                                padding: 0 10px 0px 0px;
                                background: $lightColor;
                            }
                        }
                    }
                }
                .mat-form-field-outline {
                    color: red;
                }
            }
            &.mat-focused {
                .mat-form-field-outline-thick {
                    color: red;
                    .mat-form-field-outline-start,
                    .mat-form-field-outline-gap,
                    .mat-form-field-outline-end {
                        border-width: 2px;
                    }
                }
            }
            .mat-form-field-wrapper {
                padding-bottom: 10px;
            }
        }
        .mat-form-field-wrapper {
            margin: 0;
            padding-bottom: 10px;
        }
        &.mat-form-field-appearance-outline {
            &.mat-form-field-invalid {
                .mat-form-field-outline-thick {
                    color: $border_inputs;
                    .mat-form-field-outline-start,
                    .mat-form-field-outline-gap,
                    .mat-form-field-outline-end {
                        border-width: 1px;
                    }
                }
                &.mat-focused {
                    .mat-form-field-outline-thick {
                        color: $mainColor;
                        .mat-form-field-outline-start,
                        .mat-form-field-outline-gap,
                        .mat-form-field-outline-end {
                            border-width: 2px;
                        }
                    }
                }
            }
            &.mat-form-field-can-float {
                &.mat-form-field-should-float {
                    .mat-form-field-label {
                        color: $mainColor;
                        font-weight: bold;
                        mat-label {
                            padding: 0 10px 0px 0px;
                            background: $lightColor;
                        }
                    }
                }
                &.mat-focused {
                    .mat-form-field-label {
                        color: $fiveColor;
                        font-weight: bold;
                    }
                }
            }
            .mat-form-field-outline {
                color: $border_inputs;
                height: 55px;
            }
            .mat-form-field-infix {
                padding: 0.7em 0 0.3em;
            }
            .mat-form-field-subscript-wrapper {
                padding: 0;
            }
            .mat-form-field-outline-thick {
                color: $fiveColor;
            }
        }
        .mat-form-field-label {
            color: $labels;
        }
        .mat-form-field-infix {
            height: 55px;
        }
        .mat-form-field-label-wrapper {
            top: -15px;
        }
        mat-hint {
            padding: 0;
            &.error {
                color: red;
            }
        }
    }
    mat-select {
        .mat-select-arrow-wrapper {
            transform: translateY(0);
            padding: 0;
            .mat-select-arrow {
                border: 0;
                height: 15px;
                line-height: 15px;
                width: 15px;
                &:before {
                    content: "\e900";
                    color: $mainColor;
                    font-family: $iconFont;
                }
            }
        }
    }
}
.cancel-service{
    .textarea-item{
        mat-form-field {
            &.mat-form-field-appearance-outline {
                .mat-form-field-outline {
                    height: auto;
                    min-height: 95px;
                }
            }
            textarea{
                resize: none;
                min-height: 95px;
            }
        }
    }
}
form-available{
    .mat-form-field-appearance-outline{
        .mat-select-arrow-wrapper{
            transform: translateY(0);
        }
    }
}
.radio-green{
    .mat-radio-button {
        font-family: $mainFont;
        &.mat-accent {
            .mat-radio-inner-circle {
                background: $secondColor;
            }
            &.mat-radio-checked,
            &.mat-accent:active {
                .mat-radio-outer-circle {
                    border-color: $secondColor;
                }
                .mat-radio-persistent-ripple,
                .mat-ripple-element {
                    background: $secondColor;
                }
            }
        }
        .mat-radio-container,
        .mat-radio-outer-circle,
        .mat-radio-inner-circle{
            height: 16px;
            width: 16px;
        }
        .mat-radio-outer-circle{
            border-width: 1px;
        }
        .mat-radio-label{
            white-space: inherit;
        }
        &.with-button{
            display: block;
            .mat-radio-label-content{
                align-items: center;
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
            button{
                background: transparent;
                border:0;
                color:$fiveColor;
                font-size: 16px;
                padding: 0;
                &:hover,
                &:focus{
                    outline: none;
                }
            }
        }
    }
}
.radio_buttons {
    padding-top: 20px;
    &.subscription_radios {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 4%;
        mat-radio-group {
            width: 48%;
            &:nth-child(2n) {
                margin-right: 0;
            }
            mat-radio-button {
                margin-right: 0;
                padding: 9px 15px;
                width: 100%;
                .mat-radio-label-content {
                    font-family: $mainFont;
                }
            }
        }
    }
    mat-radio-button {
        background: $lightColor;
        border: 1px solid $border_inputs;
        border-radius: 5px;
        margin-bottom: 25px;
        margin-right: 30px;
        padding: 15px;
        width: calc(50% - 15px);
        &:nth-child(2n) {
            margin-right: 0;
        }
        .mat-radio-label {
            margin: 0;
        }
    }
    .mat-radio-button {

        &.mat-accent {
            .mat-radio-inner-circle {
                background: $secondColor;
            }
            &.mat-radio-checked {
                border: 1px solid $secondColor;
            }
            &.mat-radio-checked,
            &.mat-accent:active {
                .mat-radio-outer-circle {
                    border-color: $secondColor;
                }
                .mat-radio-persistent-ripple {
                    background: $secondColor;
                }
            }
        }
    }
}
.timepicker__header {
    background: $mainColor !important;
    input {
        &:hover,
        &:focus {
            outline: none;
        }
    }
}
.timepicker__actions {
    justify-content: center !important;
}
.timepicker-button {
    color: $iu-01 !important;
}
.mat-calendar-body-cell-content.mat-calendar-body-selected{
    background: $mainColor;
    color:$secondColor;
}
.mat-calendar-body-cell-content{
    color: $secondColor;
    font-weight: bold;
}
form {
    .mat-form-field-appearance-outline,
    .mat-form-field:not(.mat-form-field-appearance-legacy){
      .mat-datepicker-toggle {
        top: -9px;
        display: block;
        position: relative;
        .mat-icon-button .mat-datepicker-toggle-default-icon,
        svg {
            display: none;
        }
        .mat-icon-button {
            background: url("./../../../assets/img/iconos/icono_calendario.svg") no-repeat center;
            background-size: contain;
            border-radius: 0;
            height: 20px;
            width: 20px;
            filter: $filter;
        }
      }
    }
    legend {
        color: $mainColor;
        display: block;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 15px;
        &.no-margin {
            margin-bottom: 2px;
        }
    }
    .form-actions {
        margin-top: 10px;
        button {
            cursor: pointer;
            display: block;
            width: 100%;
            & + button {
                margin-top: 20px;
            }
        }
    }
}
mat-datepicker-toggle{
    button{
        &:focus,
        &:hover{
            outline: none;
        }
    }
}
.calendar-input{
    .mat-form-field-flex{
        align-items: center;
    }
}
.calendarWidget{
    .mat-calendar-table-header-divider{
        &:after{
            content: none;
        }
    }
    .mat-calendar-body-cell-content{
        border-radius: 4px;
        &.mat-calendar-body-selected{
            background: $mainColor;

        }
    }
    .mat-calendar-body-today:not(.mat-calendar-body-selected){
        border-color: $mainColor;
    }
    .mat-calendar-table-header{
        color: $mainColor;
    }
    .mat-calendar-controls{
        justify-content: center;
        .mat-calendar-arrow{
            display: none;
        }
        .mat-calendar-previous-button{
            order:1;
        }
        button{
            &:focus,
            &:hover{
                outline: none;
            }
        }
        .mat-calendar-period-button{
            color:$mainColor;
            font-size: 14px;
            font-weight: bold;
            order:2;
            .mat-button-wrapper{
                background: transparent;
                outline: none;
                text-transform: lowercase;
                &::first-letter{
                    text-transform: uppercase;
                }
            }
        }
        .mat-calendar-next-button{
            color: $mainColor;
            order:3;
        }
    }
    .mat-calendar-spacer{
        display: none;
    }
}

.mat-calendar-body-disabled>.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    border: 1px solid $fiveColor;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
    background: #ffffff;
}

mat-datepicker-content{
    .mat-calendar-body-cell-content{
      background: transparent;
      border-radius: 2px;
      color:$iu-01;
    }
    .mat-calendar-body-active.selected .mat-calendar-body-cell-content,
    .selected .mat-calendar-body-cell-content,
    .mat-calendar-body-selected{
        background: $discount !important;
        color: #333333;
    }
    .mat-calendar-body-active.selected .mat-calendar-body-cell-content{
        background: $discount !important;
        color: #333333;
    }
    .mat-calendar-body-cell:not(.mat-calendar-body-disabled){
      &:hover{
        .mat-calendar-body-cell-content{
            background: $discount !important;
        }
      }
    }

    .mat-calendar-body .mat-calendar-body-label{
        padding: 0 !important;
        visibility: hidden;
    }
    .mat-calendar-table .mat-calendar-table-header tr th{
      font-weight: 600;
      font-size: 14px;
    }
  }
  .calendar-date, .mat-calendar{
    font-family: $mainFont;
    height: fit-content !important;
    .mat-calendar-header{
      .mat-calendar-controls{
        display: flex;
        width: 100%;
        justify-content: space-between;

        .mat-icon-button.mat-button-disabled.mat-button-disabled{
            color: rgba(0,0,0,.26) !important;
        }
        .mat-calendar-previous-button, .mat-calendar-next-button {
            color: $fiveColor !important;
        }

        .mat-button-wrapper span{
            color: #000000;
            font-weight: 700;
            font-size: 16px;
        }

        .mat-calendar-arrow {
            position: relative;
            margin-top: -8px;
            margin-left: 16px;
            overflow: hidden;
            padding: 0;
            min-width: 0;
            width: 12px;
            height: 12px;
            border: 0 solid $fiveColor;
            border-top-width: 2px;
            border-right-width: 2px;
            transform: translateX(-2px) rotate(135deg);
            fill: transparent;
            transition: all .5s ease-out;
        }
        & .mat-calendar-invert {
            transform: translateX(-2px) rotate(-45deg) !important;
            margin-top: 0px !important;
        }
      }
    }
    .mat-calendar-spacer{
      display: none;
    }
    .mat-calendar-table-header {
      th{
        color:$mainColor;
        font-family: $mainFont;
        font-size: 16px;
      }
    }
  }

.ServiceDataPersonForm{
    background: white;
    border-radius: 8px;
    border: 0.5px solid #cfd4cf;
    margin: 0 auto 16px;
    padding: 20px;
    width: 100%;

    .form_item{
        margin-bottom: 0;
    }
}

@media screen and (max-width: 767px) {
    form {
        padding: 0 15px;
        &.no-padding{
            padding: 0;
        }
        legend {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 10px;
        }
        .mat-form-field-appearance-outline,
        .mat-form-field:not(.mat-form-field-appearance-legacy) {
            input{
                max-width: 100%;
                padding-right: 20px;
                text-overflow: ellipsis;
            }
            .mat-datepicker-toggle{
                top: 12px;
            }
            &.mat-form-field-hide-placeholder{
              .mat-form-field-label-wrapper{
                text-overflow: ellipsis;
                max-width: 84%;
                box-sizing: border-box;
              }
            }
        }
    }
    .mat-select-panel {
        max-width: 100%;
        min-width: 100% !important;
    }
    .cancel-service{
        .mat-form-field-appearance-outline {
            .mat-form-field-label{
                font-size: 14px;
            }
        }
        mat-form-field {
            .mat-form-field-label-wrapper{
                top:-11px;
            }
        }
    }
    .form_item {
        margin-bottom: 20px;
        padding: 0;
        &.textarea {
            mat-form-field {
                &.mat-form-field-appearance-outline {
                    textarea {
                        &.mat-input-element {
                            min-height: 80px;
                        }
                    }
                }
            }
        }
        &.grid-6 {
            padding-right: 5px;
            padding-left: 5px;
        }
        .radio_group {
            .radio-img {
                margin-right: 10px;
                width: calc(50% - 5px);
            }
        }
        &.more_less {
            .material-spinners {
                a {
                    height: 50px;
                    line-height: 50px;
                }
                input {
                    height: 50px;
                }
            }
        }
        &.date_picker {
            .mat-form-field-appearance-outline {
                .mat-form-field-suffix {
                    top: 0px;
                }
            }
        }
    }
    .radio_buttons {
        mat-radio-button {
            margin-bottom: 25px;
            margin-right: 20px;
            width: calc(50% - 10px);
        }
        &.subscription_radios {
            mat-radio-group {
              margin-right: 0px;
              width: calc(48% - 5px);
            }
        }
    }
}
