.container {
   width: 100%;
   margin: {
      left: auto;
      right: auto;
   };
}

.grid {
   display: grid;
   grid-template-columns: repeat(12, 1fr);
   grid-gap: 1rem;
   @media screen and (max-width:767px) {
      grid-gap: 0;
   }
}

@for $i from 1 through 12 {
   .grid-#{$i} {
      grid-column: span ($i);
   }
}

@include mq-min(sm) {
   @for $i from 1 through 12 {
      .grid-sm-#{$i} {
         grid-column: span ($i);
      }
   }
}

@include mq-min(md) {
   @for $i from 1 through 12 {
      .grid-md-#{$i} {
         grid-column: span ($i);
      }
   }
}

@include mq-min(lg) {
   @for $i from 1 through 12 {
      .grid-lg-#{$i} {
         grid-column: span ($i);
      }
   }
}

@include mq-min(lg) {
   @for $i from 1 through 12 {
      .grid-xl-#{$i} {
         grid-column: span ($i);
      }
   }
}
