// ==========================================================================
// Anchos minimos (min-width) : @include mq-min(sm) {}
// ==========================================================================
@mixin mq-min($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

// ==========================================================================
// Anchos maximos (max-width) : @include mq-max(sm) {}
// ==========================================================================
@mixin mq-max($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

// ==========================================================================
// Anchos maximos / minimos (max-width and min-width) : @include mq-between(sm, md) {}
// ==========================================================================
@mixin mq-between($lower, $upper) {
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
  } @else {
    @if (map-has-key($breakpoints, $lower) ==false) {
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }
    @if (map-has-key($breakpoints, $upper) ==false) {
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}
//@include orientation(landscape) 
@mixin orientation($direction) { 

  $orientation-landscape: "(orientation:landscape)"; 
  $orientation-portrait: "(orientation:portrait)";

  @if $direction == landscape {
    @media #{$orientation-landscape} { @content; } 
  }
  @if $direction == portrait {
    @media #{$orientation-portrait} { @content; } 
  }
} 

@function hex-to-rgba($color: #fff, $opacity: 0.5) {
  @return rgba(red($color), green($color), blue($color), $opacity);
}
@mixin prefix($property, $value, $prefixes: ()) {
  @each $prefix in $prefixes {
    #{'-' + $prefix + '-' + $property}: $value;
  }
  // Output standard non-prefixed declaration
  #{$property}: $value;
}
//example @include placeholder('.form-control',#000000, 600, 12px); no se coloca directamente al input si no al papa
@mixin placeholder($selector, $color, $font, $size) {
  #{$selector}::-webkit-input-placeholder {
    color: $color;
    font-weight: $font;
    $size: font-size;
  }
  #{$selector}::-moz-placeholder {
    color: $color;
    font-weight: $font;
    $size: font-size;
  }
  #{$selector}:-ms-input-placeholder {
    color: $color;
    font-weight: $font;
    $size: font-size;
  }
}
@mixin horizontal-gradient ($startColor, $endColor) {
  background-color: $startColor;
  background-image: -webkit-gradient(linear, left top, right top, from($startColor), to($endColor));
  background-image: -webkit-linear-gradient(left, $startColor, $endColor);
  background-image: -moz-linear-gradient(left, $startColor, $endColor);
  background-image: -ms-linear-gradient(left, $startColor, $endColor);
  background-image: -o-linear-gradient(left, $startColor, $endColor);
}
@mixin linear-gradient($direction, $color-stops) {
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}
