estado-producto{
    display: block;
    margin-bottom: 50px;
    .tabs-items{
        h1{
            color:$mainColor;
        }
        .gray-txt{
            color: $ui-05;
        }
        .status{
            h2{
                color:$iu-01;
            }
            .others-dates{
                ul{
                    li{
                        a{
                            color: $ui-05;
                            font-weight: bold;
                            &:after{
                                background: $secondColor;
                            }
                            &.active,
                            &:hover{
                                color: $secondColor;
                            }
                        }
                    }
                }
            }
        }
        .tabs-content{
            .item{
                &.finished{
                    color:$mainColor;
                    span{
                        color: $mainColor;
                    }
                }
                &.active{
                    color:$secondColor;
                    span{
                        color: $secondColor;
                    }
                }
                &.canceled{
                    color:$error;
                    font-weight: normal;
                    span{
                        color:$error;
                    }
                }
                span{
                    color: $ui-05;
                }
            }
        }   
    }
}
estado-process-service,
estado-route-service,
estado-detail-card,
estado-agenda-card{
    .card-process,
    .card-route,
    .card-detail,
    .card-agenda{
        .box{
            .title{
                color: $mainColor;
            }
        }
    }   
}
.card-process{
    .status-item{
        .material-icons{
            color: $mainColor;
        }
    }
    .box{
        .item{
            &.more-items{
                background: linear-gradient(106.26deg, #75298C -0.01%, #2E0063 99.99%);
                color:$lightColor;
            }
            .gray-txt{
                color: $ui-05;
            }
        }
    }
}
.card-linetime{
    .box{
        .small{
            font-size: 12px;
        }
    }
}
.card-route{
    .item-txt{
        a{
            color: #017eff;
            text-decoration: underline;
        }
    }
}
.card-agenda{
    .box{
        .content{
            .item{
                &:after{
                    background: $mainColor;
                }
                &.success,
                &:last-child,
                &:first-child{
                    &:after{
                        background:$secondColor;
                    }
                }
                &.success,
                &:last-child{
                    color:$secondColor;
                }
                &.alert{
                    color:$error;
                }
            }
        }
        .bar{
            .bar-line{
                .porcent{
                    background: $secondColor;
                }
            }
        }
        .btns-options{
            .item-btn{
                .btn{
                    box-shadow: none;
                    font-size: 14px;
                    padding: 0 10px;
                    min-width: 192px;
                    &.btn-primary.outline{
                        padding: 0 10px;
                    }
                    .material-icons{
                        font-size: 14px;
                    }
                }
                p{
                    font-size: 12px;
                    line-height: 14px;
                }
            }
        }
    }
}
.detail-boxes{
    .content-box{
        .content-cols{
            .col{
                p{
                    &.gray-txt{
                        color: $ui-05;
                    }
                }
            }
        }
    }
}
    
.dialog-widget{
    .close{
        color:$fiveColor;
        opacity: 1;
    }
    .info-top{
        .title{
            color:$mainColor;
            font-family: $mainFont;
        }
    }
    .text{
        p{
            strong{
                color: $secondColor;
                font-weight: bold;
            }
        }
    }
    .mat-dialog-actions{
        .btn{
            &.outline{
                box-shadow: none;
            }
        }
        .btn-primary {
            &:not(:disabled):not(.disabled):active,
            &:active:not(:disabled){
                background: $lightColor;
                border:1px solid $secondColor;
                box-shadow: 0 2px 2px 0 rgba(0,0,0,0.24);
                color: $secondColor;
                outline: none;
            }
            &.outline,
            &:not(:disabled):not(.disabled):active,
            &:active:not(:disabled){
                background: $lightColor;
                border:2px solid $secondColor;
                color: $secondColor;
            }
        }
    }
    form-available{
        .grid {
            .grid-12{
                &.grid-sm-6{
                    margin-bottom: 15px;
                }
            }
        }
    }
    &.dialog-reschedule{
        .mat-dialog-actions{
            margin-top: 10px;
            .btn{
                &.outline{
                    border:0;
                }
            }
        }
    }
}
.mat-snack-bar-container.snack-widget{
    background-color:hex-to-rgba($darkColor,0.8);
    box-shadow: none;
    color:$lightColor;
    font-size: 14px;
    margin:0 auto 30px;
    max-width: 790px;
    text-align: left;
    vertical-align: 1px;
    width: 790px;
    .mat-simple-snackbar{
        font-size: 14px;
        line-height: 18px;
        justify-content: space-between;
    }
    .mat-button{
        color:$secondColor;
        display: inline-block;
        font-size: 12px;
        font-weight: bold;
        vertical-align: middle;
        &:after{
            content: "\e908";
            color:$lightColor;
            font-family: $iconFont;
            margin-left: 15px;
        }
    }
} 
form-available{
    form{
        padding: 0;
    }
    .mat-progress-bar{
        margin-bottom: 20px;
        .mat-progress-bar-buffer{
            background: rgb(214, 253, 211);
        }
        .mat-progress-bar-fill{
            &:after{
                background: $secondColor;
            }
        }
    }
    .legend{
        color:$mainColor;
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        margin-bottom: 10px;
    }
    .container{
        padding: 0;
    }
    .grid{
        .grid-12{
            &.grid-sm-6{
                margin-bottom: 25px;
                padding-right: 8px;
                & + .grid-sm-6{
                    padding: 0 0 0 8px;
                }
            }
        }
        .mat-form-field{
            width: 100%;
            &.mat-focused{
                .mat-form-field-outline-thick{
                    color:$mainColor;
                }  
            }
            .mat-form-field-required-marker{
                display: none;
            }
        }
    }
}
.ecosystem_confirmation_dialog {
    .mat-dialog-container {
        border-radius: 16px;
    }
}
.content-cards.detail-boxes{
    .title{
        color:$mainColor;
    }
    .content-box{
        .content{
            .info{
                ul{
                    li{
                        &:before{
                            background: $mainColor;
                        }
                    }
                }
            }
        }
    }
}
.photos-service{
    .bk-modal{
        background: $mainColor;
    }
    .dialog-photos{
        .icon-close{
            color: $lightColor;
        }
        .slide{
            p{
                color: $lightColor;
                .gray-txt{
                    color: $ui-05;
                    display: inline-block;
                    margin-right: 25px;
                }
            }
        }
    }
    .swiper-button-prev, 
    .swiper-button-next{
        &:before{
            color:$lightColor;
        }
        &.swiper-button-disabled{
            &:before{
                opacity: 0.6;
            }
        }
    }
}
@media screen and (min-width:768px){
    .mat-snack-bar-container.snack-widget{
        .mat-button{
            min-height: inherit;
            line-height: inherit;
            &:after{
                display: inline-block;    
                vertical-align: 2px;
            }
        }
    } 
}
@media screen and (max-width:960px){
    .mat-snack-bar-handset{
        .mat-snack-bar-container.snack-widget{
            width: 650px;
        }
    }
    .photos-service{
        .slick-prev{
            bottom: -36px;
            left: calc(50% - 40px);
            transform: none;
            top: inherit;
        }
        .slick-next{
            bottom: -36px;
            right: calc(50% - 40px);
            transform: none;
            top: inherit;
        }
    }
}
@media screen and (max-width:767px){
    .mat-snack-bar-handset{
        .mat-snack-bar-container.snack-widget{
            .mat-simple-snackbar{
                font-size: 12px;
                line-height: 18px;
            }
            .mat-button{
                margin: 0;
                padding: 0 10px;
                &:after{
                    content: none;
                }
            }
        }
    }
    .card-agenda {
        .box .btns-options {
            .item-btn {
                .btn{
                    min-width: inherit;
                }
            }
        }
    }   
    .dialog-widget{
        form{
            padding: 0;
        }
        .mat-dialog-actions{
            .btn{
                &.outline{
                    border:0;
                }
            }
        }
    }
    form-available{
        .grid{
            .grid-12{
                padding: 0;
                &.grid-sm-6{
                    padding: 0;
                    padding-right: 8px;
                    & + .grid-sm-6{
                        padding: 0;
                        padding-left: 8px;
                    }
                }
            }
        }
    }
}