/* You can add global styles to this file, and also import other style files */
@import './assets/scss/global/base';
@import './assets/scss/design/files';

:root{
  --c-lib-tb-primaryBase2: #2e0063;
  --filter-bgTertiaryBase: invert(0) sepia(0) saturate(100%) hue-rotate(0) brightness(100%) contrast(100%);
  --c-lib-tb-primaryBasebtn: #2e0063;
  --c-secondBase: #82e778;
  --m-lib-footter: 25px;
  --p-lib-footer: 50px;
}
