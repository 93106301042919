@import "../global/variables";

html {
  font-size: 62.5%;
}
body {
  color: $iu-01;
  font-family: $mainFont;
  font-size: 16px;
  line-height: 24px;
  @media screen and (max-width: 1024px) {
    position: relative !important;
  }
}
.btn-primary {
  @extend %btn-green;
  &.outline {
    @extend %btn_outline;
    &.disabled {
      background: $lightColor;
      border-color: $u1-04;
      color: $u1-04;
    }
  }
  &.disabled {
    background: $u1-04;
    border-color: $u1-04;
    color: $lightColor;
    pointer-events: none;
  }
}
.main_container {
  .btn-primary {
    &:not(:disabled):not(.disabled):active,
    &:active:not(:disabled) {
      background: $lightColor;
      border: 1px solid $secondColor;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24);
      color: $secondColor;
      outline: none;
    }
    &.outline {
      &:not(:disabled):not(.disabled):active,
      &:active:not(:disabled) {
        background: $lightColor;
        border: 2px solid $secondColor;
        color: $secondColor;
      }
    }
  }
}
.btn_outline {
  @extend %btn_outline;
}
.container {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}
.btn_green {
  @extend %btn-green;
}
.link_purple {
  @extend %links;
  &:hover,
  &:focus {
    outline: none;
  }
}
.swiper-button-prev,
.swiper-button-next {
  align-items: center;
  display: flex;
  height: 40px;
  line-height: 33px;
  justify-content: center;
  right: 0;
  width: 40px;
  z-index: 2;
  &:after {
    content: none;
  }
  &:before {
    background: transparent;
    color: $fiveColor;
    font-family: $iconFont;
    font-size: 36px;
    height: 100%;
    opacity: 1;
    text-align: center;
    transition: all 0.4s;
    width: 100%;
  }
  &:hover,
  &:focus {
    opacity: 1;
    color: $fourColor;
    &:before {
      color: $fiveColor;
    }
  }
  &.swiper-button-disabled {
    opacity: 1;
    &:before {
      color: #cccccc;
      opacity: 1;
    }
  }
  @media screen and (max-width: 1024px) {
    visibility: hidden;
  }
}
.swiper-button-prev {
  right: inherit;
  &:before {
    content: "\e91d";
  }
}
.swiper-button-next {
  &:before {
    content: "\e91e";
  }
}

.slider-product {
  .container_items {
    .swiper-button-next {
      right: 3px;
      height: 36px;
      width: 36px;
      border-radius: 30px;
      background-color: #fff;
      -webkit-box-shadow: 4px 4px 5px 0px rgba(255, 255, 255, 1);
      -moz-box-shadow: 4px 4px 5px 0px rgba(255, 255, 255, 1);
      box-shadow: 4px 4px 5px 0px rgba(255, 255, 255, 1);

      &:hover::before {
        color: #100039;
      }
    }
    .swiper-button-prev {
      left: 3px;
      height: 36px;
      width: 36px;
      border-radius: 30px;
      background-color: #fff;
      -webkit-box-shadow: 4px 4px 5px 0px rgba(255, 255, 255, 1);
      -moz-box-shadow: 4px 4px 5px 0px rgba(255, 255, 255, 1);
      box-shadow: 4px 4px 5px 0px rgba(255, 255, 255, 1);

      &:hover::before {
        color: #100039;
      }
    }
  }
}

.container_testimonials,
.container_slick {
  .carousel {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 1300px;
    overflow: hidden;
    padding: 0 5rem 5rem;
    position: relative;
    .swiper-pagination-bullets {
      bottom: 2rem;
      display: block;
      height: 17px;
      overflow: hidden;
      z-index: inherit;
      .swiper-pagination-bullet {
        &.swiper-pagination-bullet-active {
          //background: transparent;
          background: $mainColor;
          &:before {
            background: $mainColor;
          }
        }
      }
    }
  }
  .swiper-button-next {
    right: 0px;
  }
  .swiper-button-prev {
    left: 0;
  }
}
app-breadcrumb {
  display: block;
  padding-top: 2px;
}
.dots-purple {
  .slick-dotted {
    padding-bottom: 0;
  }
  .slick-dots {
    li {
      display: none;
      margin: 0;
      margin-right: 5px;
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        display: inline-block;
        position: static;
        &.slick-active {
          position: static;
        }
      }
      &:nth-child(4) {
        margin-right: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &.slick-active {
        display: inline-block;
        position: absolute;
        right: -4px;
        top: 12px;
        &:last-child {
          right: 1px;
        }
        button {
          border: 1px solid $mainColor;
          &:before {
            background: $mainColor;
          }
        }
      }
      button {
        height: 10px;
        width: 10px;
        &:before {
          height: 6px;
          margin-left: -3px;
          margin-top: -3px;
          width: 6px;
        }
      }
    }
  }
}

.container_testimonials {
  .slick-track {
    display: flex;
    justify-content: center;
    &:before,
    &:after {
      content: none;
    }
    .slick-slide {
      height: auto;
    }
  }
  .slick-next {
    right: -45px;
  }
}
.layout-content-top {
  display: block;
  margin-top: 80px;
  &.m-t {
    margin-top: 165px;
  }
}
.layout_content {
  margin-top: 87px;
  .container_statics {
    .container {
      max-width: 840px;
    }
  }
}
.container_statics {
  margin-bottom: 40px;
  .content {
    h1 {
      margin-bottom: 54px;
      margin-top: 40px;
      @extend %title-section;
    }

    .legal__sub {
      font-size: 10px;
      line-height: 24px;
      margin-bottom: 25px;
    }
    .legal__content {
      margin-bottom: 10px;
      text-align: justify;
    }
    .legal__paragraph {
      display: inline-block;
      font-size: 12px;
      line-height: 20px;
    }

    .legal__subtitle {
      color: $mainColor;
      font-weight: 700;
      margin-bottom: 50px;
      margin-top: 50px;
      @extend %subtitles;
    }
    .legal__subtitle_item {
      color: $mainColor;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 25px;
    }
    .legal__link {
      color: $mainColor;
      font-weight: 700;
      text-decoration: none;
    }
  }
}
.mat-snack-bar-container {
  background-color: $mainColor;
  color: white;
  text-align: center;
  border-radius: 5px;
  padding: 16px;
  font-size: 17px;
  .mat-simple-snackbar-action {
    color: $errorSnack;
  }
  &.message-snack {
    background: #333333;
    box-shadow: none;
    font-size: 14px;
    margin: 0 auto;
    max-width: 100%;
    padding: 15px 20px;
    width: 790px;
    .mat-button-wrapper {
      color: $secondColor;
      text-transform: uppercase;
    }
    .content-dialog {
      position: relative;
      .title {
        color: $mainColor;
        font-family: $mainFont;
        font-size: 28px;
        font-weight: bold;
        margin: 0 0 30px 0;
        text-align: center;
      }
      .close-m {
        appearance: none;
        background: transparent;
        border: 0;
        color: $mainColor;
        position: absolute;
        top: -20px;
        right: -15px;
      }
      .mat-dialog-content {
        margin: 0;
        overflow: hidden;
        padding: 0;
        .grid {
          margin: 0;
          .form_item {
            margin-bottom: 20px;
            padding: 0;
          }
        }
        .checkbox_item {
          mat-checkbox {
            .mat-checkbox-label {
              font-size: 16px;
              line-height: 24px;
              a {
                color: $controls;
                font-size: 16px;
                line-height: 24px;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
  button {
    &:focus,
    &:hover {
      outline: none;
    }
  }
}
.cdk-overlay-pane {
  &.full-location {
    &.modal-map {
      height: 750px;
      .mat-dialog-content {
        max-height: 100%;
      }
    }
  }
}
.mat-progress-spinner {
  margin: 15px auto 20px;
  circle {
    stroke: $mainColor;
  }
}

#layout-top {
  &.hide-logo {
    #menu-categories {
      &.sticky {
        top: 71px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
@media screen and (max-width: 1100px) {
  .container_slick {
    .slick-next {
      right: -30px;
    }
    .slick-prev {
      left: -40px;
    }
  }
  .dots-purple {
    .slick-next {
      right: -40px;
    }
  }
  .container_testimonials {
    .slick-next {
      right: -40px;
    }
  }
}

@media screen and (max-width: 960px) {
  .container_slick {
    .slick-next {
      right: -20px;
    }
    .slick-prev {
      left: -40px;
    }
    &:not(.jelpitpacks),
    &.whiteCards {
      .swiper-button-next,
      .swiper-button-prev {
        display: none;
      }
    }
  }
  .dots-purple {
    .slick-next {
      right: -40px;
    }
  }
  .layout-content-top {
    margin-top: 80px;
  }
  .mat-snack-bar-handset {
    .mat-snack-bar-container {
      &.message-snack {
        box-shadow: none;
        font-size: 12px;
        margin: 0 auto;
        padding: 15px;
        text-align: left;
        width: 100%;
      }
    }
  }
  .mat-snack-bar-container {
    &.message-snack {
      box-shadow: none;
      font-size: 12px;
      text-align: left;
      padding: 15px;
      width: 100%;
    }
  }
}
@media screen and (max-width: 767px) {
  .layout-content-top {
    margin-top: 111px;
  }
  #layout-top {
    margin-top: 72px;
    &.hide-logo {
      margin-top: 104px;
    }
  }
  app-banner-slider,
  app-banner-detail {
    &#layout-top {
      margin-top: 72px;
      &.hide-logo {
        margin-top: 60px;
      }
    }
  }
  .pageserror {
    &#layout-top {
      margin-top: 100px;
      &.hide-logo {
        margin-top: 100px;
      }
    }
  }

  .mat-dialog-container {
    max-height: 100vh !important;
  }
  .cdk-overlay-pane.modal-address {
    max-width: 100% !important;
    left: calc(-50% + 20px) !important;
    position: fixed !important;
    transform: translateX(50%) !important;
    width: calc(100% - 20px) !important;
    &#cdk-overlay-1,
    &#cdk-overlay-0 {
      left: 0 !important;
      transform: translateX(0%) !important;
      top: 0;
      width: 100% !important;
    }
    &.full-location,
    &.full-modal {
      height: 100vh;
      left: 0 !important;
      transform: translateX(0%) !important;
      width: 100% !important;
      &.modal-map {
        height: 100vh;
      }
      .mat-dialog-container {
        border-radius: 0;
      }
    }
  }
  .layout_content {
    margin-top: 115px;
  }
  .container_statics {
    .content {
      .legal__subtitle {
        margin-bottom: 35px;
        margin-top: 30px;
      }
    }
  }
  .container_testimonials,
  .container_slick {
    &:not(.jelpitpacks) {
      .carousel {
        padding: 0 1.6rem 5rem;
      }
    }
  }
}

.mat-tab-body-content {
  overflow: hidden !important;
}

.mat-ink-bar {
  background-color: #4eb449 !important;
  border: solid 1.5px #4eb449;
  border-radius: 50px 50px 0px 0px;
}

.mat-tab-label-active {
  color: $mainColor;
  font-weight: bold;
  .iconInclude {
    color: $secondColor;
  }
  .iconNotInclude {
    color: $error;
  }
}

.mat-tab-label {
  width: 50% !important;
  opacity: 1 !important;
  font-family: $mainFont !important;
  font-size: 16px;
}

.mat-dialog-container {
  // padding: 0 !important;
  overflow-x: hidden !important;
}

.mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
  display: none !important;
}

.mat-tab-group {
  font-family: $mainFont !important;
}

.mat-expansion-panel-header {
  height: 68px !important;
  padding-right: 3px !important;
  padding-bottom: 5px !important;
  padding-left: 16px !important;
}

.mat-expansion-panel-spacing {
  margin: 0px !important;
}

.mat-expansion-panel-spacing {
  box-shadow: none !important;
}

.mat-expansion-panel {
  box-shadow: none !important;
}
.mat-expansion-panel-body {
  padding: 0 11px 23px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  cursor: not-allowed;
  user-select: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  //cursor: not-allowed;
  //user-select: none;
}
