.icn{
    display: inline-block;
    height: 28px;
    vertical-align: middle;
    width: 28px;
}
.icon-hogar{
    background: url(./../../../assets/img/pictogramas/hogar.svg);
    background-size: contain;
}
.icon-bienestar{
    background: url(./../../../assets/img/pictogramas/bienestar.svg);
    background-size: contain;
}
.icon-mascotas{
    background: url(./../../../assets/img/pictogramas/mascotas.svg);
    background-size: contain;
}
.icon-empresas{
    background: url(./../../../assets/img/pictogramas/empresas.svg);
    background-size: contain;
}
.icon-eventos{
    background: url(./../../../assets/img/pictogramas/eventos.svg);
    background-size: contain;
}
.icon-legal{
    background: url(./../../../assets/img/pictogramas/legal.svg);
    background-size: contain;
}
.icon-personal{
    background: url(./../../../assets/img/pictogramas/personal.svg);
    background-size: contain;
}
.icon-tecnico{
    background: url(./../../../assets/img/pictogramas/servicio_tecnico.svg);
    background-size: contain;
}
.icon-limpieza{
    background: url(./../../../assets/img/pictogramas/limpieza.svg);
    background-size: contain;
}
.icon-automoviles{
    background: url(./../../../assets/img/pictogramas/autos.svg);
    background-size: contain;
}
.icon-bonos{
    background: url(./../../../assets/img/pictogramas/bonos.svg);
    background-size: contain;
}
.icon-combos{
    background: url(./../../../assets/img/pictogramas/combos.svg);
    background-size: contain;
}
.icon-jelpitpacks{
    background: url(./../../../assets/img/pictogramas/jelpitpacks.svg);
    background-size: contain;
}
.icon-user-profile{
    background: url(./../../../assets/img/pictogramas/perfil.svg);
    background-size: contain;
}
.icon-user-suscription{
    background: url(./../../../assets/img/pictogramas/icono-estrella.svg);
    background-size: contain;
}
.icon-user-request{
    background: url(./../../../assets/img/pictogramas/solicitudes.svg);
    background-size: contain;
}
.icon-user-close{
    background: url(./../../../assets/img/pictogramas/cerrar_sesion.svg);
    background-size: contain;
}
