.open-search,
.close-search{
    appearance: none;
    background: transparent;
    border:0;
    color:color(primary,base);
    cursor: pointer;
    box-shadow: none;
    text-align: center;
    &:hover,
    &:focus{
        outline: none;
    }
    .text{
        display: block;
        font-size: 1.2rem;
    }
}
.not-results{
    font-size: 1.4rem;
    padding: 1rem 1rem 2rem;
    text-align: center;
    img{
        display: block;
        height: auto;
        margin:0 auto 5px;
        width: 45px;
    }
}
.close-search{
    color:$fiveColor;
    display: none;
    left: 1.6rem;
    position: fixed;
    top:1.6rem;
    z-index: 1;
}

.search-autocomplete{
    position: relative;
    transition: all .5s;
    &.full{
        background: $lightColor;
        left: 0;
        padding: 6rem 0 1.6rem;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 99;
        .close-search{
            display: block;
        }
    }
    form{
        display: flex;
        padding: 0 1.6rem;
        @include placeholder('.form-group', #4A4A4A, normal, 14px);
        .form-group{
            max-width: 65%;
            width: 100%;
            input{
                border:1px solid $fiveColor;
                border-radius: 4px;
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
                font-family: $mainFont;
                font-size: 1.4rem;
                height: 40px;
                padding: 0 1.2rem;
                width: 100%;
                &:hover,
                &:focus{
                    outline: none;
                }
            }
            &.service-input{
                input{
                    background: url('https://microsites-search-stage.s3.amazonaws.com/assets/img/icons/search.svg') no-repeat center white;
                    background-size: 14px;
                    background-position-x: 1.2rem;
                    max-width: 100%;
                    overflow: hidden;
                    padding-left: 3rem;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    &::-webkit-search-cancel-button{
                        appearance: none;
                        background:url('https://microsites-search-dev.s3.amazonaws.com/assets/img/icons/clear-button.svg') no-repeat center;
                        background-size:contain;
                        -webkit-appearance: none;
                        height: 15px;
                        width: 15px;
                    }
                }
            }
            &.city-input{
                max-width: 35%;
                input{
                    background: url(https://microsites-search-stage.s3.amazonaws.com/assets/img/icons/expand-arrow.svg) no-repeat 50% #fff;
                    background-size: 14px;
                    background-position-x: calc(100% - 12px);
                    border-left: 0;
                    border-radius: 4px;
                    border-bottom-left-radius: 0;
                    border-top-left-radius: 0;
                    padding-right: 25px;
                    &::-webkit-search-cancel-button{
                        appearance: none;
                        background:url('https://microsites-search-dev.s3.amazonaws.com/assets/img/icons/clear-button.svg') no-repeat center;
                        background-size:contain;
                        -webkit-appearance: none;
                        height: 14px;
                        right: 12px;
                        width: 14px;
                    }
                    &:focus{
                        background:$lightColor;
                        padding-right: 12px;
                    }
                }
            }
        }
    }
}
.recommended-items{
    background: #FCFAFF;
    margin:0 -1.6rem;
    padding: 1.1rem 1.6rem;
    width: calc(100% + 3.2rem);
    h2{
        font-size: 2rem;
        line-height: 2.4rem;
        margin-bottom: 1rem;
    }
}
.autocompleteCustom{
    font-family: $mainFont;
    padding: 1.6rem;
    &.autocompleteCity{
        padding: 0;
    }
    .cat{
        color:$secondColor;
        display: block;
        font-size: 1.6rem;
        margin-bottom: 1.2rem;
        margin-top: 1.5rem;
        padding: 0;
        &:first-of-type{
            margin-top: 0;
        }
    }
    .all-results{
        color:$fiveColor;
        padding: 1rem 0;
        text-align: right;
        text-decoration: underline;
        a{
            color:$fiveColor;
        }
    }
    .label-price{
        font-size: 1.2rem;
        display: inline-block;
        margin-right: 1.2rem;
        vertical-align: middle;
    }
    .city-label{
        font-size: 2rem;
        padding: 1.5rem 1.6rem 0.8rem;
    }
    .price{
        display: inline-block;
        font-size: 1.6rem;
        font-weight: bold;
        color:$secondColor;
        vertical-align: middle;
    }
    .is-loading{
        margin:1rem 0;
    }
    .mat-option{
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        font-family: $mainFont;
        font-size: 1.6rem;
        height: auto;
        line-height: inherit;
        margin-bottom: 1.2rem;
        padding: 1.2rem 1.6rem;
        position: relative;
        .discount{
            background: #F7F0FF;
            bottom: 0;
            border-bottom-left-radius: 50px;
            border-top-left-radius: 50px;
            color:color(primary,base);
            font-size: 1.6rem;
            font-weight: bold;
            padding: 0.2rem 0.8rem;
            position: absolute;
            right: 0;
        }
        &:hover,
        &:focus{
            background: transparent;
            opacity: 0.8;
        }
        .product,
        .city{
            color:color(primary,base);
            font-weight: bold;
            margin-bottom: 0.5rem;
        }
        .state{
            color:$secondColor;
        }
        &.option-loader{
            border-bottom: 0;
            box-shadow: none;
            margin-bottom: 0;
            padding: 0;
        }
        &.city-option{
            border:0;
            border-bottom: 1px solid color(border,color-2);
            border-radius: 0;
            box-shadow: none;
            padding: 1.2rem 1.6rem;
        }
    }
    &.mat-autocomplete-panel{
        background: color(grayscale,white);
        box-shadow: none;
        height: calc(100vh - 11.6rem);
        left: 0;
        max-height:calc(100vh - 11.6rem);
        padding-bottom: 5rem;
        position: fixed;
        top: 11.6rem;
        width: 100%;
    }
}

.color-primary{
    color: $mainColor;
}
.color-light-primary{
    color: $fiveColor;
}

#searcher-home{
    .form-group{
      border-right: 0.5px solid $mainColor;
      margin:0;
    }
    .container{
        padding: 0;
    }
}
#searcher-header{
    display: none;
    max-width: 590px;
    opacity: 0;
    position: absolute;
    top: -72px;
    right: 240px;
    transition: all .5s;
    visibility: hidden;
    width: 100%;
    app-searcher{
        margin-top: 0;
    }
    &.show-search{
        opacity: 1;
        visibility: visible;
        display: block;
    }
    .banner-searcher{
        padding: 0;
        .container{
            padding: 0;
        }
    }
    .search-autocomplete {
        form{
            .form-group{
                margin:0;
            }
        }
    }
}
app-page-results{
    .pager {
        ul {
            li {
                &.page-prev,
                &.page-next {
                    a {
                        .material-icons{
                            display: none;
                        }
                        &:after {
                            content: "\e90b";
                            display: inline-block;
                            font-family: $iconFont;
                            font-size: 15px;
                            height: 15px;
                            line-height: 12px;
                        }
                    }
                }
                &.page-next {
                    a {
                        &:after {
                            content: "\e90c";
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width:767px){
    .open-search{
        display: block;
    }
    .close-search{
        display: none;
    }
    .recommended-items{
        h2{
            margin-bottom: 0;
        }
    }
    .search-autocomplete{
        &.full{
            background: transparent;
            left: inherit;
            padding: 0;
            position: relative;
            top: inherit;
            width: 100%;
            z-index: 1;
            .close-search{
                display: none;
            }
        }
        form{
            .form-group{
                max-width: 585px;
                width: 100%;
                input{
                    height: 40px;
                    width: 100%;
                }
                &.city-input{
                    max-width: 205px;
                }
            }
            .btn-search{
                border-radius: 0;
                border-bottom-right-radius: 4px;
                border-top-right-radius: 4px;
                height: 40px;
                margin: 0;
                min-width: inherit;
                text-align: center;
                width: 40px;
                .material-icons{
                    display: block;
                }
                .text{
                    display: none;
                }
            }
        }
    }
    .autocompleteCustom{
        border:1px solid #EEEEEE;
        padding: 1.6rem 1.6rem 0;
        position: relative;
        .all-results{
            padding: 2rem 0 1rem;
        }
        .cat{
            margin-bottom: 0.3rem;
            margin-top: 2rem;
            padding: 0;
            &:first-of-type{
                margin-top: 2rem;
            }
        }
        .mat-option{
            border-radius: 0;
            border-bottom: 1px solid #D8D8D8;
            box-shadow: none;
            font-size: 1.6rem;
            line-height: 2rem;
            margin-bottom: 0;
            padding: 1.2rem 0;
            &:last-child{
                border-bottom: 0;
            }
            &.option-loader{
                padding: 0;
            }
            .state,
            .product,
            .city{
                font-size: 1.4rem;
                line-height: 1.8rem;
            }
        }
        &.mat-autocomplete-panel{
            border-radius: 4px;
            height: auto;
            max-height: 500px;
            padding-bottom:0;
            position: relative;
            top:0;
            width: 100%;
        }
    }
}
@media screen and (max-width:1100px){
    #searcher-header{
        right: 230px;
    }
}
@media screen and (max-width:960px){
    #searcher-header{
        width: 100vw;
        max-width: none;
        right:-15px;
        top: 0px;
        app-searcher{
            max-width: 100%;
        }
        .banner-searcher{
            padding:16px 0;
        }
    }
}
@media screen and (max-width:767px){
    .search-autocomplete{
        &.full{
            height: 100vh;
        }
    }
    #searcher-home{
        display: block;
        transition: all .5s;
        &.sticky{
            position: fixed;
            top: 78px;
            z-index: 99;
            transition: all .5s;
            width: 100%;
            .description,
            .title-banner{
                display: none;
            }
            .banner-searcher{
                padding-top: 2rem;
            }
            app-searcher{
                margin-top: 0;
            }
            &.hide-logo{
                top: 59px;
            }
        }
    }
}
@media screen and (min-width:767px){
    #searcher-header {
        .banner-searcher{
            padding: 1rem 0;
        }
    }
}
