// @import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:300,300i,400,400i,700,700i&display=swap");
// @import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");

@font-face {
  font-family: 'iconFont';
  src:  url('../../../assets/fonts/iconFont/iconFont.eot');
  src:  url('../../../assets/fonts/iconFont/iconFont.eot') format('embedded-opentype'),
    url('../../../assets/fonts/iconFont/iconFont.ttf') format('truetype'),
    url('../../../assets/fonts/iconFont/iconFont.woff') format('woff'),
    url('../../../assets/fonts/iconFont/iconFont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}