%links{
    color: $fiveColor;
    font-family: $mainFont;
    font-size: 14px;
    font-weight: bold;
    line-height: 21px;
    text-align: center;
    text-transform: uppercase;
    &:hover,
    &:focus{
        opacity: 0.8;
        outline: none;
        text-decoration: none;
    }
}
%title-section{
    color: $mainColor;
    font-family: $mainFont;
    font-size: 36px;
    font-weight: bold;
    line-height: 48px;
    text-align: center;
}
%paragraph{
    font-size: 16px;
    color: $iu-01;
    line-height: 26px;
}
%subtitles{
    color: $mainColor;
    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
    margin:0 0 18px;
}
%title-category{
    color: $mainColor;
    font-size: 32px;
    font-weight: bold;
    line-height: 38px;
    margin:0 0 16px;
    text-align: center;
}
%texts{
    font-size: 18px;
    color: $iu-01;
    line-height: 24px;
}
%btn-green{
    background: $secondColor;
    border:1px solid $secondColor;
    border-radius: 50px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.24);
    color: $lightColor;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    padding: 0 45px;
    text-transform: uppercase;
    transition: all .5s;
    text-align: center;
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:active:not(:disabled),
    &:hover,
    &:focus{
        background: $lightColor;
        border:1px solid $secondColor;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.24);
        color: $secondColor;
        outline: none;
        transition: all .5s;
        text-decoration: none;
    }
    &:disabled,
    &.disabled{
        background: $ui-05;
        border:1px solid $ui-05;
        box-shadow: none;
        color:$lightColor;
        cursor: not-allowed;
    }
}
%btn_outline{
    background: $lightColor;
    border:2px solid $secondColor;
    border-radius: 50px;
    color: $secondColor;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    padding: 0 45px;
    text-transform: uppercase;
    transition: all .5s;
    text-align: center;
    &:active,
    &:not(:disabled):not(.disabled):active,
    &:active:not(:disabled),
    &:hover,
    &:focus{
        background: $lightColor;
        border:2px solid $secondColor;
        color: $secondColor;
        outline: none;
        opacity: 0.6;
    }
}
%description{
    font-size: 16px;
    color: $u1-04;
    line-height: 24px;
}

%blockFluid{
	left: -50vw;
	margin-left: 50%;
    overflow: hidden;
    position: relative;
    width: 100vw;
}
@media screen and (max-width:767px){
    %title-section{
        font-size: 26px;
        line-height: 34px;
    }
    %texts{
        font-size: 14px;
        line-height: 21px;
    }
    %title-category{
        font-size: 20px;
        line-height: 24px;
    }
}