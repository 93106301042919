$darkColor:#000000;
$lightColor:#ffffff;

$mainColor: var(--c-lib-tb-primaryBase);
$mainFooterColor: var(--c-lib-tb-primaryBase2);
$secondColor: var(--c-lib-tb-secondaryBase);
$thirdColor:#0B0029;
$fourColor:#100039;
$fiveColor: var(--c-lib-tb-bgTertiaryBase);
$sixColor:var(--c-lib-tb-successL100);
$secondBase: var(--c-secondBase);

$iu-01:#333333;
$ui-02:#F8FAFE;
$ui-03:#E6FAE4;
$u1-04:#666666;
$ui-05:#999999;
$ui-06:#E6FAE4;
$ui-07:#F9F9F9;
$ui-08:#1B1B1B;

$controls: #0074FC;
$labels:#979797;
$border:#D8D8D8;
$border_inputs:#D7D7D7;
$border_light:#EEEEEE;
$disabled:#F7F7F7;
$discount:var(--c-lib-tb-tertiaryL400);
$borderCard: var( --c-lib-tb-bgSecondaryD100);
$tag:var(--c-lib-tb-bgSecondaryBase);

$error:#DC3545;
$errorSnack: var(--c-lib-tb-errorBase);
$grayscale-1:#FBFBFB;

$mainFont: 'Roboto Condensed', sans-serif;
$secondFont:'Open Sans', sans-serif;
$iconFont:'iconFont';

$breakpoints: ( xs:330px, sm: 600px, sxm: 767px, md: 960px,  ml:1100px, lg: 1285px, xl: 1920px );
$filter: var(--filter-bgTertiaryBase);

$infoBase: var(--c-lib-tb-infoBase);
$infoLight: var( --c-lib-tb-infoL300);
$infoLighter: var(--c-lib-tb-infoL400);

$footerBottomMargin: var(--m-lib-footter);
$footerBottomPadding: var(--p-lib-footer);
