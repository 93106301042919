ngx-material-timepicker-container{
    position: relative;
    z-index: 1100;
}
.dialog-location {
    padding: 15px 40px 5px;
    position: relative;
    &.map-modal{
        .subtitle{
            margin-bottom: 15px;
        }
    }
    .title {
        color: $mainColor;
        font-family: $mainFont;
        font-size: 28px;
        font-weight: bold;
        margin: 0 0 20px 0;
        text-align: center;
    }
    .icon-close {
        appearance: none;
        background: transparent;
        border:0;
        color: $fiveColor;
        position: absolute;
        top: 0px;
        right: 0px;
    }
    .subtitle {
        color: $iu-01;
        margin: 0 0 28px 0;
        text-align: center;
    }
    #mapGoogle{
        margin-bottom: 10px;
    }
    .form_item {
        margin-bottom: 15px;
        position: relative;
        .icon-add-user-button1 {
            color: $fiveColor;
            position: absolute;
            right: 30px;
            top: 17px;
            cursor: pointer;
        }
        .mat-form-field-appearance-outline {
            .mat-form-field-flex {
                padding-right: 35px;
            }
        }
        mat-form-field{
            .mat-form-field-label {
                font-size: 14px;
            }
            &.mat-form-field-should-float {
                .mat-form-field-label {
                    top: 1.6rem;
                }
                .mat-form-field-infix{
                    border-top: 1rem solid transparent;
                }
            }
        }
    }
    .old-location {
        margin-top: 5px;
        .label-item {
            color: $mainColor;
            font-family: $mainFont;
            font-weight: bold;
            margin: 0 0 5px 0;
            padding: 0 15px;
        }
        .content-list {
            border: 1px solid #d7d7d7;
            border-radius: 5px;
            color: $iu-01;
            font-size: 14px;
            .location-item {
                border-bottom: 1px solid #d7d7d7;
                cursor: pointer;
                padding: 15px 10px;
                &:last-child {
                    border-bottom: 0;
                }
                .icon-history {
                    color: $mainColor;
                    display: inline-block;
                    font-weight: bold;
                    margin-right: 5px;
                }
                p {
                    margin: 0;
                }
                .city {
                    color: #666666;
                    display: inline-block;
                    margin-left: 15px;
                }
                &.my-location {
                    color: $secondColor;
                    text-transform: uppercase;
                    .icon-aim {
                        margin-right: 5px;
                    }
                }
            }
        }
    }
    .mat-dialog-actions {
        margin: 0;
        min-height: inherit;
        padding: 0;
        .btn-primary {
            height: 36px;
            line-height: 36px;
            width: 100%;
        }
    }
}
.modal-register,
.modal-register-m{
    .mat-dialog-container{
        padding: 0;
    }
}
.dialog-block-user {
    padding: 30px 20px 5px;
    position: relative;
    .title {
        color: $darkColor;
        font-family: $mainFont;
        font-size: 20px;
        font-weight: bold;
        line-height: 23px;
        margin: 0 0 10px 0;
        text-align: center;
    }
    .icon-close {
        appearance: none;
        background: transparent;
        border:0;
        color: $fiveColor;
        position: absolute;
        cursor: pointer;
        font-size: 18px;
        top: 0px;
        right: 0px;
    }
    .subtitle {
        color: $iu-01;
        margin: 0 0 10px 0;
        text-align: center;
    }
}
.modal-cart-error{
    mat-dialog-title,
    .mat-dialog-title{
        color:$mainColor;
        font-size: 24px;
        font-weight: bold;
        line-height: 30px;
        margin:0 0 25px;
        text-align: center;
    }
    .mat-dialog-content{
        text-align: center;
        .text{
            margin:0 0 15px;
        }
        .list-products{
            text-align: left;
            ul{
                li{
                    margin-bottom: 10px;
                }
            }
        }
    }
    .mat-dialog-actions{
        margin: 0;
        padding-bottom: 0;
        button{
            @extend %btn-green;
        }
    }
}
.deliverModal{
    .mat-dialog-container{
        max-height: 90vh;
        overflow: auto;
    }
}
.dialog-address{
    padding: 9px 26px;
    &.saved-address{
        padding-bottom: 0;
    }
    .content-dialog{
        position: relative;
    }
    .title {
        color: $mainColor;
        font-family: $mainFont;
        font-size: 24px;
        font-weight: bold;
        margin: 0 0 30px 0;
        text-align: left;
    }
    .icon-close {
        appearance: none;
        background: transparent;
        border:0;
        color: $mainColor;
        font-size: 20px;
        position: absolute;
        top: 0px;
        right: 10px;
    }
    .subtitle {
        color: $mainColor;
        font-size: 18px;
        font-weight: bold;
        margin: 15px 0 0;
    }
    .form_item {
        margin-bottom: 18px;
    }
    .location-item{
        margin:20 0;
        text-align: center;
    }
    .my-location {
        border: 1px solid #d7d7d7;
        border-radius: 5px;
        color: $secondColor;
        display: inline-block;
        cursor: pointer;
        margin-bottom: 18px;
        padding: 10px 25px;
        &:hover,
        &:focus{
            text-decoration: none;
        }
    }
    .message-locate{
        text-align: center;
        .error{
            color:red;
            font-size: 11px;
            line-height: 14px;
        }
    }
    .map-element{
        position: relative;
        text-align: center;
        &:before{
            background: #d7d7d7;
            content:'';
            display: block;
            height: 1px;
            left: 0;
            position: absolute;
            top: 14px;
            width: 100%;
        }
        .op-text{
            background: $lightColor;
            display: inline-block;
            padding: 2px 15px;
            position: relative;
        }
        .label-map{
            color:$mainColor;
            margin-bottom: 10px;
        }
        .map{
            height: 279px;
        }
    }
    .mat-dialog-actions{
        button{
            display: block;
            width: 100%;
        }
    }
}
.mat-dialog-container{
    .btn-primary {
        &:not(:disabled):not(.disabled):active,
        &:active:not(:disabled){
            background: $lightColor;
            border:1px solid $secondColor;
            box-shadow: 0 2px 2px 0 rgba(0,0,0,0.24);
            color: $secondColor;
            outline: none;
        }
    }
}
@media screen and (max-width: 767px) {
    .dialog-location {
        padding: 0;
        .title {
            font-size: 24px;
            line-height: 28px;
            margin: 0 0 10px 0;
            padding: 0 20px;
        }
        .icon-close {
            top: 5px;
        }
        .subtitle {
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 18px;
        }
        .mat-dialog-content {
            margin: 0 -20px;
            padding: 0 20px;
            .row{
                margin:0;
            }
        }
        form{
            padding: 0;
        }
        .form_item {
            margin-bottom: 15px;
            padding: 0;
            .icon-add-user-button1 {
                right: 15px;
            }
            .mat-input-element {
                font-size: 14px;
            }
            mat-form-field {
                .mat-form-field-label {
                    font-size: 14px;
                    top: 1.9rem;
                }
            }
        }
        .old-location {
            margin-top: 0;
            .label-item {
                font-size: 14px;
            }
            .content-list {
                .location-item {
                    padding: 15px 12px;
                    p {
                        align-items: center;
                        display: flex;
                        max-width: 98%;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        span,
                        strong {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
    .dialog-block-user {
        padding: 60px 35px 50px;
        .icon-close {
            top: 25px;
            right: 25px;
        }
    }
    .deliverModal{
        left: 0!important;
        max-width: 100%!important;
        min-height: 100vh;
        width: 100%!important;
        transform: none!important;
        .mat-dialog-container{
            border-radius: 0;
            max-height: 100vh;
            padding: 59px 10px;
        }
    }
    .full-modal{
        .mat-dialog-content{
            max-height: 90vh;
        }
        .mat-dialog-container{
            margin:0;
            padding: 0;
        }
        &.actions-fixed,
        &.modal-address{
            form{
                padding: 0;
            }
            .mat-dialog-content{
                margin: 0;
                min-height: calc(100vh - 130px);
                max-height: inherit;
                overflow: hidden;
                padding: 0;
            }
            .mat-dialog-actions{
                margin-bottom: 40px;
            }
        }
        &.actions-fixed{
            .mat-dialog-content{
                min-height: calc(100vh - 190px);
            }
        }
        &.modal-error{
            height: fit-content;
            .mat-dialog-container{
                border-radius: 8px;
            }
        }
    }
    .dialog-address{
        padding: 30px 20px 0;
        .title{
            max-width: 240px;
        }
        .icon-close{
            right: 10px;
        }
        .content-text{
            .row{
                margin:0;
            }
        }
        .form_item {
            padding: 0;
        }
    }
}
@media screen and (max-width:767px) and (orientation:landscape){
    .dialog-address {
        .title{
            max-width: 300px;
        }
    }
    .full-modal{
        &.actions-fixed{
            .dialog-address.saved-address{
                padding-bottom: 40px;
            }
            .mat-dialog-content{
                min-height: calc(100vh - 125px);
                max-height: inherit;
            }
        }
    }
}

.pac-container{
  position: fixed !important;
}

::ng-deep{
  .mat-form-field-appearance-outline .mat-form-field-outline{
    border: 2px red !important;
  }
}
