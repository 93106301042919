@import "../global/variables";
.banner-searcher{
    background: $mainColor !important;
    color: $lightColor;
    font-size: 1.4rem;
    line-height: 1.6rem;
    padding: 2.4rem 0 1.6rem;
    text-align: center;
    .title-banner{
        font-size: 2rem;
        line-height: 2.3rem;
        margin:0 0 0.5rem 0;
    }
    .search-element{
        display: flex;
        justify-content: center;
        margin: 2rem auto 0;
        max-width: 790px;
    }
}
app-searcher{
    display: block;
    margin: 2rem auto 0;
    max-width: 790px;
}

@media screen and (min-width: 767px) {
    .banner-searcher{
        font-size: 2rem;
        line-height: 2.4rem;
        padding: 3rem 0;
        .title-banner{
            font-size: 3.2rem;
            line-height: 3.7rem;
            margin:0 0 0.5rem 0;
        }
    }
}
