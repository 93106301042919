[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: $iconFont;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-arrow_down:before {
    content: "\e900";
}
.icon-arrow_left:before {
  content: "\e901";
}
.icon-arrow_right:before {
  content: "\e902";
}
.icon-arrow_up:before {
  content: "\e903";
}
.icon-facebook:before {
  content: "\e904";
}
.icon-icono_carrito:before {
  content: "\e905";
}
.icon-icono_usuario:before {
  content: "\e906";
}
.icon-instagram:before {
  content: "\e907";
}
.icon-close:before {
  content: "\e908";
}
.icon-menu:before {
  content: "\e909";
}
.icon-icon_WA:before {
  content: "\e90a";
}
.icon-thin-arrow-left:before {
  content: "\e90b";
}
.icon-thin-arrow-right:before {
  content: "\e90c";
}
.icon-left-chevron:before {
  content: "\e90d";
}
.icon-right-chevron:before {
  content: "\e90e";
}
.icon-gps:before {
  content: "\e90f";
}
.icon-information-solid:before {
  content: "\e910";
}
.icon-google-drive-document:before {
  content: "\e911";
}
.icon-history:before {
  content: "\e912";
}
.icon-information:before {
  content: "\e913";
}
.icon-pin-location:before {
  content: "\e914";
}
.icon-circle-arrow-left:before {
  content: "\e91d";
}
.icon-arrow-left:before{
  content: "\e5e0";
}
.icon-circle-arrow-right:before {
  content: "\e91e";
}
.icon-star-filled:before {
  content: "\e915";
}
.icon-star-outline:before {
  content: "\e916";
}
.icon-facebook-circle:before {
  content: "\e917";
}
.icon-instagram-circle:before {
  content: "\e918";
}
.icon-linkedin-circle:before {
  content: "\e919";
}
.icon-youtube-circle:before {
  content: "\e91a";
}
.icon-check:before {
  content: "\e91b";
}
.icon-date:before {
  content: "\e91c";
}
.icon-add-user-button1:before {
  content: "\e937";
}
.icon-watch:before {
  content: "\e91f";
}
.icon-big-play:before {
  content: "\e920";
}
.icon-search-item:before {
  content: "\e921";
}
.icon-circle-check:before {
  content: "\e922";
}
.icon-circle-cross:before {
  content: "\e923";
}
